<!--
 * @Author: your name
 * @Date: 2020-05-22 15:41:42
 * @LastEditTime: 2021-01-22 15:50:13
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\match\component\application.vue
-->
<template>
  <div class="application-wrapper">
      <div class="application-head">
        报名人数：<span>{{form.applicationTotal}}人</span> 今日新增：<span>{{form.day}}人</span> 本周新增： <span>{{form.week}}人</span>
      </div>
    <div class="table-wrapper">
      <el-table
				:data="tableData"
        :height="tableHeight - 200"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <!-- <el-table-column
					type="index"
          align="center"
					label="序号"
          fixed="left"
					width="55">
				</el-table-column> -->
        <div v-for="(item, index) in tableHeadDataList" :key="index">
          <el-table-column
            :key="`table_${item.prop}`"
            :show-overflow-tooltip="true"
            :sortable="item.sortable"
            :prop="item.prop"
            :align="item.align"
            :label="item.label"
            v-if="registerList.includes(item.prop)"
            :minWidth="item.width ? item.width : ''">
          </el-table-column>
        </div>
        <el-table-column
					prop="createTime"
          header-align="center"
          align="center"
          min-width="220"
          show-overflow-tooltip
					label="报名时间">
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div>
        <el-button :disabled="tableData.length === 0" round size="mini" type="primary" @click="exportUrlExcel(`${apis.courseExport}?courseId=${id}`, {})">导出</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <div class="echart-wrapper">
      <div class="middle-charts-time-block" style="height:250px;width:50%;margin:0 auto"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/index'
import apis from '@/apis'
import echarts from "echarts";
import download from '@/mixins/download'

export default {
  components: {},
  mixins: [mixin, download],
  props: {},
  data() {
    return {
      id: null,
      form: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        day: 0,
        applicationTotal: 0,
        week: 0,
      },
      tableData: [],
      tableHeadDataList: [],
      registerList: [],
      charts: {
        timeDistribution: null,
        xDate: [],
        dayTrends: [],
        accumDayTrends: [],
      },
    }
  },
  watch: {},
  computed: {
    ...mapState(['Language', 'DROPDOWNBOX']),
  },
  methods: {
    getEchartData(id) {
      this.id = id
      this.$http.get(`${apis.courseApplyInfo}?courseId=${id}`, this.form).then((res) => {
        if (res.data.code === 0) {
          this.form.day = res.data.rows.day
          this.form.day = res.data.rows.day
          this.form.applicationTotal = res.data.rows.total
          this.form.week = res.data.rows.week
          this.xDate = res.data.rows.dayTrends.map(item => item.date)
          this.dayTrends = res.data.rows.dayTrends.map(item => item.count)
          this.accumDayTrends = res.data.rows.accumDayTrends.map(item => item.count)
          this.setTimeReport()
        }
      })
    },
    // 获取报名课程列表
    searchData() {
      this.$http.post(apis.courseApplyInfoList, {
        ...this.form,
        courseId: this.$route.query.id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.getEchartData(this.$route.query.id);
          this.tableData = res.data.rows
          Object.keys(res.data.rows[0]).forEach((key) => {
            this.registerList.push(key)
          })
          this.form.total = res.data.total
          // 最后如果只有一个数据且id=0，
          if (res.data.rows.length === 1 && res.data.rows[0].id === null) {
            this.tableData = []
            this.form.total = 0
          }
        }
      })
    },
     // 初始化--拆换次数-适用时间分布
    setTimeReport() {
      const option3 = {
        // color: ["#4E8CD5"],
        color: ['#4E8CD5', '#ED7C30'],
        title: {
          text: "报名人数（日）",
          x: "center",
        },
        xAxis: {
          type: "category",
          data: this.xDate,
        },
        yAxis: {
          type: 'value',
          name: '人数',
          show: true,
          // interval: 50,
        },
        series: [
          {
            name: "每日报名人数",
            data: this.dayTrends,
            type: "bar",
            // itemStyle: {
            //     normal: {
            //       color: () => {
            //         return "#439BFF"
            //       },
            //     },
            // },
          },
          {
            name: "累计报名总人数",
            data: this.accumDayTrends,
            type: "line",
            // itemStyle: {
            //     normal: {
            //       color: red,
            //     },
            // },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
              type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
          textStyle: {
            align: 'left',
          },
          // formatter(params) {
          //   const str = "";
          //   return `操作日期：${params[0].name}<br>TSN：${
          //     params[0].value
          //   }<br>${str}`;
          // },
          // axisPointer: {
          //   type: "cross",
          //   label: {
          //     backgroundColor: "#4E8CD5",
          //   },
          // },
        },
        legend: {
          data: [
            {
              name: "每日报名人数",
              //  icon: 'circle',
              //  textStyle: {
              //       color: '#439BFF', // 图例文字颜色
              //  },
            },
            {
              name: "累计报名总人数",
              //  icon: 'circle',
              //  textStyle: {
              //       color: '#439BFF', // 图例文字颜色
              //  },
            },
          ],
          // data: ["TSN"],
          bottom: "-5",
        },
      };
      this.charts.timeDistribution.setOption(option3);

      //让图表随着窗口尺寸改变自适应大小
      const that = this;
      window.onresize = () => {
        that.charts.timeDistribution.resize();
      };
    },
  },
  created() {
    this.registerList = this.DROPDOWNBOX.VENUE_APPLY_INFO
    this.registerList.forEach((item) => {
      this.tableHeadDataList.push({
        prop: item.value,
        align: 'center',
        label: item.label,
        width: '130',
      })
    })
  },
  mounted() {
    this.charts.timeDistribution = echarts.init(
      document.getElementsByClassName("middle-charts-time-block")[0],
    );
  },
};
</script>

<style scoped lang="less">
.application-wrapper {
  padding: 20px;
  .application-head {
    margin-bottom: 10px;
    color: #606266;
  }
  .page-wrapper {
    margin-top: 20px
  }
  .echart-wrapper {
    text-align: center;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
